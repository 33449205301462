@import "../../App.scss";

main {
  width: 100vw;
  overflow: hidden;

  section {
    #project {
      width: 60%;
      margin: 2em auto 0;

      @include respond-to(handhelds) {
        width: calc(100% - 2em);
      }

      #project-photo {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 20vw;
        border: 2px solid clr(mid);
        border-radius: 5px;
      }

      h1 {
        text-transform: uppercase;
      }

      h3 {
        font-size: 1rem;
        font-weight: bold;
        opacity: 0.7;
      }

      p {
        padding: 0;
        font-size: 1rem;
        opacity: 0.7;
      }

      #demo {
        height: 200px;
        width: 200px;
        border: 2px solid clr(mid);
        border-radius: 5px;
        margin: 1em auto;
      }

      #code {
        padding: 1em;
        border-radius: 5px;
        background-color: clr(mid);
      }
    }
  }
}
