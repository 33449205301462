@import url("https://fonts.googleapis.com/css?family=Dosis");

$colors: (
  dark: #444,
  lightdark: #ccc,
  mid: #2f3640,
  light: #fff,
  light-hover: darken(#fff, 10%),
  green: #26c281,
  red: #ec644b,
  yellow: #eccc68,
  shadow: rgba(0, 0, 0, 0.17),
  invisible: rgba(0, 0, 0, 0)
);

@function clr($name) {
  @return map-get($colors, $name);
}

$break-small: 900px;
$break-large: 901px;
$shadow: 0 0 6px 2px clr(shadow);

@mixin respond-to($media) {
  @if $media==handhelds {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media==medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
      @content;
    }
  } @else if $media==wide-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  }
}

.bg-pan-right {
  -webkit-animation: bg-pan-right 8s both;
  animation: bg-pan-right 8s both;
}

.focus-in-expand {
  -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.focus-in-contract-bck {
  -webkit-animation: focus-in-contract-bck 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: focus-in-contract-bck 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.text-shadow-pop-tl {
  -webkit-animation: text-shadow-pop-tl 0.6s both;
  animation: text-shadow-pop-tl 0.6s both;
}

@-webkit-keyframes bg-pan-right {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes bg-pan-right {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@-webkit-keyframes text-shadow-pop-tl {
  0% {
    text-shadow: 0 0 clr(mid), 0 0 clr(mid), 0 0 clr(mid), 0 0 clr(mid),
      0 0 clr(mid), 0 0 clr(mid), 0 0 clr(mid), 0 0 clr(mid);
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: -1px -1px clr(mid), -2px -2px clr(mid), -3px -3px clr(mid),
      -4px -4px clr(mid), -5px -5px clr(mid), -6px -6px clr(mid),
      -7px -7px clr(mid), -8px -8px clr(mid);
    -webkit-transform: translateX(8px) translateY(8px);
    transform: translateX(8px) translateY(8px);
  }
}

@keyframes text-shadow-pop-tl {
  0% {
    text-shadow: 0 0 clr(mid), 0 0 clr(mid), 0 0 clr(mid), 0 0 clr(mid),
      0 0 clr(mid), 0 0 clr(mid), 0 0 clr(mid), 0 0 clr(mid);
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: -1px -1px clr(mid), -2px -2px clr(mid), -3px -3px clr(mid),
      -4px -4px clr(mid), -5px -5px clr(mid), -6px -6px clr(mid),
      -7px -7px clr(mid), -8px -8px clr(mid);
    -webkit-transform: translateX(8px) translateY(8px);
    transform: translateX(8px) translateY(8px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-10-8 20:38:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

body,
#root {
  margin: 0;
  font-family: "Barlow", serif;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: clr(light);
}

.code {
  font-family: "VT323", monospace;
}

#messages {
  position: fixed;
  display: none;
  width: 50vw;
  padding: 0.5em;
  opacity: 0.94;
  margin: 0 auto;
  border-radius: 5px;
  top: 75px;
  left: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
  color: clr(light);
  z-index: 999;

  a {
    color: clr(dark);
  }

  a:hover {
    color: clr(mid);
  }

  @include respond-to(handhelds) {
    margin-top: 1em auto;
    width: calc(100vw - 5em);
  }
}

a {
  text-decoration: none;
  outline: none;
  color: clr(light);
}

a:hover {
  color: clr(light-hover);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 999px clr(light) inset !important;
  -webkit-text-fill-color: clr(dark) !important;
}
