@import "../../App.scss";

#footer {
  margin: 2em auto;
  display: flex;
  text-align: center;

  .grid {
    width: 100%;
    grid-gap: 1em;

    .grid-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: fit-content;
      margin: 0 auto;
      grid-gap: 2em;

      @include respond-to(handhelds) {
        display: flex;
        flex-flow: row wrap;
      }

      .address-group {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        cursor: default;

        .footer-location {
          text-align: left;
          color: clr(light);
        }

        .footer-email {
          text-align: left;
          text-transform: uppercase;
          color: clr(light);
          font-size: 11px;
          opacity: 0.6;
        }
      }

      .link-group-wrapper {
        display: flex;
        flex-direction: column;

        h3 {
          margin-top: 0;
          margin-right: 1em;
          text-decoration-color: clr(light);
          text-transform: uppercase;
          align-self: flex-start;
          font-family: "Karla", sans-serif;
          font-weight: bold;
          opacity: 0.7;
          cursor: default;
        }

        .link-group {
          align-self: flex-start;
          display: flex;

          @include respond-to(handhelds) {
            margin: 0 0.5em;
          }

          a {
            display: block;
            width: 28px;
            height: 28px;
            font-size: 28px;
            line-height: 28px;
            width: fit-content;
            margin: 0 7px 1em;
            opacity: 0.3;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .grid-2 {
      a {
        font-size: 11px;
      }

      a:first-of-type {
        margin-right: 0.5em;
      }

      a:last-of-type {
        margin-left: 0.5em;
      }
    }
    a {
      width: fit-content;
      margin: 0 auto;
      #bytewave {
        width: 84px;
        height: 32px;
        margin: 0 auto;
        background-image: url("../../img/bytewave-text.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        filter: grayscale(100%);
      }

      #bytewave:hover {
        filter: grayscale(0);
      }
    }

    #copyright {
      color: clr(light-hover);
      cursor: default;
      font-size: 11px;
      padding-bottom: 2em;

      @include respond-to(handhelds) {
        font-size: 9px;
      }

      #login {
        font-size: 9px;
      }
    }
  }
}
