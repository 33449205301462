@import "../../App.scss";

main {
  width: 100vw;
  overflow: hidden;

  section {
    width: 100%;
    margin: 0;

    #filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-bottom: 600px;
      z-index: 4;
      background-image: linear-gradient(
        270deg,
        #6c7a89,
        #f2f1ef,
        #95a5a6,
        #67809f
      );
      background-size: 600% 100%;
      animation-duration: 25s;
      opacity: 0.36;
    }

    #background {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 600px;
      background: url("../../img/background-highres.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 3;
      filter: grayscale(0.2);
      overflow: hidden;

      #name {
        margin-top: 160px;
        height: calc(30vw * 0.1721);
        width: 30vw;
        background: url("../../img/name.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        animation-delay: 0.4s;
        animation-duration: 3s;
        box-shadow: $shadow;
        z-index: 5;

        @include respond-to(handhelds) {
          height: calc(80vw * 0.1721);
          width: 80vw;
        }
      }
    }

    #filter::before,
    #background::before {
      content: "";
      width: 100%;
      height: 600px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to bottom, transparent 300px, clr(dark) 93%);
    }

    #about-start {
      width: fit-content;
      margin: -96px 0 0 3.4em;
      display: block;
      font-size: 48px;
      text-transform: uppercase;
      color: clr(light);
      animation-delay: 3s;
      animation-duration: 3s;
      font-family: "Karla", sans-serif;
      font-weight: bold;
      letter-spacing: 0.06em;
      cursor: default;

      @include respond-to(handhelds) {
        width: fit-content;
        font-size: 40px;
        margin: -96px auto;
      }
    }

    #about {
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;

      @include respond-to(handhelds) {
        width: calc(100% - 2em);
      }

      p {
        width: 48%;
        padding: 1.2em 0 0;
        cursor: default;

        @include respond-to(handhelds) {
          margin-top: 130px;
        }
      }

      div {
        #school1 {
          height: calc(10vw * 0.8858);
          width: 10vw;
          background: url("../../img/s-ku.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          filter: grayscale(1);

          @include respond-to(handhelds) {
            height: calc(14vw * 0.8858);
            width: 14vw;
          }
        }

        #school2 {
          height: calc(10vw * 0.6181);
          width: 10vw;
          margin-top: 4em;
          background: url("../../img/s-uccs.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          filter: grayscale(1);
          opacity: 0.7;

          @include respond-to(handhelds) {
            height: calc(14vw * 0.6181);
            width: 14vw;
          }
        }
      }
    }

    #work-start {
      margin: 0 0 0 -30px;
      display: block;
      font-size: 400px;
      line-height: 350px;
      text-transform: uppercase;
      color: clr(light);
      font-family: "Karla", sans-serif;
      font-weight: bold;
      letter-spacing: 0.11em;
      opacity: 0.25;
      cursor: default;
      text-shadow: -1px -1px clr(mid), -2px -2px clr(mid), -3px -3px clr(mid),
        -4px -4px clr(mid), -5px -5px clr(mid), -6px -6px clr(mid),
        -7px -7px clr(mid), -8px -8px clr(mid);
      -webkit-transform: translateX(8px) translateY(8px);
      transform: translateX(8px) translateY(8px);
    }

    #work-container {
      margin-top: calc(-240px - 1em);
      height: fit-content;
      width: 100vw;
      overflow-x: scroll;

      #works {
        width: fit-content;
        display: flex;
        padding: 1em;
        min-height: calc(240px + 1em);

        .work {
          display: block;
          position: relative;
          height: 240px;
          width: 240px;
          min-width: 240px;
          margin-right: 1em;
          padding: 2em;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          background-color: clr(dark);
          border-radius: 5px;
          box-shadow: $shadow;
          overflow: hidden;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            background-color: lighten(clr(dark), 0.6);
            box-shadow: 0 0 2px 1px clr(shadow);

            .work-img {
              filter: grayscale(0);
            }
          }

          .work-img {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 140px;
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            filter: grayscale(1);
            border-bottom: 2px solid clr(mid);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          .work-title {
            margin: 100px 0 0 0;
            text-transform: uppercase;
            color: clr(light);
            font-family: "Karla", sans-serif;
            font-weight: bold;
            text-shadow: -1px -1px clr(mid), -2px -2px clr(mid);
            -webkit-transform: translateX(2px) translateY(2px);
            transform: translateX(2px) translateY(2px);
            text-align: center;
          }

          .work-date {
            text-transform: uppercase;
            color: clr(light);
            font-size: 13px;
            margin: 0;
            opacity: 0.4;
            font-family: "Karla", sans-serif;
          }

          .badge {
            position: absolute;
            z-index: 7;
            bottom: -2px;
            right: 1px;
            height: 50px;
            width: 50px;
            color: clr(light);
            display: flex;
            justify-content: flex-end;
            align-content: flex-end;

            i {
              display: block;
              line-height: 50px;
              font-size: 50px;
              opacity: 0.3;
            }
          }
        }
      }
    }
  }
}
